import React from 'react'
import gal1 from '../assets/box-1-300x300.jpeg'
import gal2 from '../assets/box-2-300x300.jpeg'
import gal3 from '../assets/box-3-300x300.jpeg'
import gal5 from '../assets/box-5-300x300.jpeg'
import gal6 from '../assets/box-6-300x300.jpeg'

function Gallery() {
  return (
    <div className='hidden'>
        <section>
  <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
    <header>
      <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
        Product Collection
      </h2>

      
    </header>

    <ul className="grid gap-4 mt-8 sm:grid-cols-2 lg:grid-cols-3">
      <li>
        <a href="#" className="block overflow-hidden group">
          <img
            src={gal1}
            alt=""
            className=" w-full object-cover transition duration-500 group-hover:scale-105 "
          />

       
        </a>
      </li>

      <li>
        <a href="#" className="block overflow-hidden group">
        <img
            src={gal2}
            alt=""
            className=" w-full object-cover transition duration-500 group-hover:scale-105 "
          />

         
        </a>
      </li>

      <li>
        <a href="#" className="block overflow-hidden group">
        <img
            src={gal3}
            alt=""
            className=" w-full object-cover transition duration-500 group-hover:scale-105 "
          />

       
        </a>
      </li>

      <li>
        <a href="#" className="block overflow-hidden group">
        <img
            src={gal5}
            alt=""
            className=" w-full object-cover transition duration-500 group-hover:scale-105 "
          />

        
        </a>
      </li>
      <li>
        <a href="#" className="block overflow-hidden group">
        <img
            src={gal6}
            alt=""
            className=" w-full object-cover transition duration-500 group-hover:scale-105 "
          />

        
        </a>
      </li>
    </ul>
  </div>
</section>
    </div>
  )
}

export default Gallery