import Header from '../components/Header';
import Banner from '../components/Banner';
import Problem from '../components/Problem';
import Gallery from '../components/Gallery';
import Blog from '../components/Blog';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

function Home() {
  
  return (
    
    <div className="App space-y-0 flex flex-col relative">
     
      <Header />
      <Banner/>
      
      <Problem/>
      <Gallery/>
      <Blog/>
      <Contact />
      <Footer/>
    </div>
  );
}

export default Home;
