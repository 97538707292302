import React from 'react'
import plaz from '../assets/plazimg.png'

export default function Header() {
  return (
    <header className="bg-gray-500 bg-opacity-50">
  <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
    <div className="flex h-16 items-center justify-between">
      <div className="flex-1 flex items-center gap-12">
        <a className="block inline-grid grid-cols-2 items-center" href="/">
          
          <img src={plaz} width={50} height={50}/>
          <span className=" text-white text-xl">Plazteck</span>
        </a>
      </div>

      <div className="md:flex md:items-center md:gap-12">
        <nav aria-label="Global" >
          <ul className="flex items-center gap-6 text-lg">
            <li>
              <a
                className="text-white transition hover:text-red-500"
                href="/contact"
              >
                Contact
              </a>
            </li>

           

           

            <li>
              <a
                className="text-white transition hover:text-red-500"
                href="/blog"
              >
                Blog
              </a>
            </li>

            

            
          </ul>
        </nav>

      </div>
    </div>
  </div>
</header>
  )
}
