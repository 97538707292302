import React from 'react'
import ic from '../assets/icon.png'
import ic2 from '../assets/icon2.png'
import ic3 from '../assets/icon3.png'

export default function Problem() {
  return (
    <section className="bg-white">
  <div
    className="m-10  lg:flex lg:h-screen lg:items-center bg-white"
  >
    <div className="mx-auto text-center ">
      <h1 className="text-3xl font-extrabold sm:text-5xl">
      <p>Creative Problem Solving</p>
        <strong className="font-extrabold text-red-700 sm:block ">
          ---
        </strong>
      </h1>

      <p className="mt-4 md:px-40 sm:text-xl/relaxed">
      In-mould labeling is an advanced method of branding moulded plastic consumer containers. IML provides greater decorating options than conventional processes.
      </p>

      <div className="m-8 justify-center gap-4 grid grid-cols-1 md:grid-cols-3">
        <a
          className="block w-full rounded  px-12 py-3 text-sm font-medium text-red-600 shadow focus:outline-none focus:ring active:bg-red-500 sm:w-auto"
          href=""
        >
          <img src={ic} width={100} height={100} className='mx-auto'/>
          <p className='mt-5'>Reconfigurable</p>
          <p className='text-black py-5'>
            Easily order and customize and reshape your product to suit your needs.</p>
        </a>

        <a
          className="block w-full rounded px-12 py-3 text-sm font-medium text-red-600 shadow hover:text-red-700 focus:outline-none focus:ring active:text-red-500 sm:w-auto"
          href=""
        >
          <img src={ic3} width={100} height={100} className='mx-auto'/>
          <p className='mt-5'>Functional Design</p>
          <p className='text-black py-5'>
          Strict testing to make sure your product has a reinforced and elegant design that is durable.</p>
        </a>
        <a
          className="block w-full rounded px-12 py-3 text-sm font-medium text-red-600 shadow hover:text-red-700 focus:outline-none focus:ring active:text-red-500 sm:w-auto"
          href=""
        >
          <img src={ic2} width={100} height={100} className='mx-auto'/>
          <p className='mt-5'>Effective</p>
          <p className='text-black py-5'>
          Mechanically and thoughtfully. Our development process speeds up design, testing and production.</p>
        </a>
      </div>
    </div>
  </div>
</section>
  )
}
