import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import iml from '../assets/iml.webp'

function Blog() {
  return (
    <div>
         <Header/>
         <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Branding</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How In-Mould Labeling Can Improve Brand Image</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              From a consumer standpoint, the packaging of a product plays a crucial role in the enhancement of 
              a brand's image. One could make the case that the label of a product acts as the corporate face 
              of a brand, which means that poorly applied labeling (whether it is creased, poorly aligned or 
              displaying substandard quality) will affect the consumer's perception of the product and therefore
               the brand in question.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={iml}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
              For companies that are seeking to increase their consumer interest and protect their products
              against contamination and counterfeiting, in-mould labelling (IML) provides the ideal labeling 
              technology. A product decoration method that has experienced a rise in popularity throughout the past
               couple of years, today we find numerous industries taking an active role in this technology,
                including food, beverage, cosmetic, healthcare and even pharmaceutical.
              </p>
             

              <p className="mt-8">
              IML stands out thanks to its ability to provide better durability, reduce waste and costs, and 
              deliver a noticeable improvement in the packaging's quality. With the capability to insert superior 
              quality printed labeling directly into a mould, whereby the label becomes a part of the packaging, 
              the consumer is more likely to be won over by the attractive and seamless design and therefore be
               more inspired to purchase the product.

              </p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Conclusion.</h2>
              <p className="mt-6">
              The IML market worldwide looks set to thrive over the coming years, with a reduction in labeling 
              costs being a major factor to this projected growth. Aside from being 100% recyclable and 
              eliminating the need for labeling processes on manufacturing lines, IML's key strength is its 
              ability to help boost packaging appeal, prevent label tampering and guarantee superior adhesion. 
              This means that, as long as there is a demand for high quality packaging, clear instruction 
              labelling or the product requires a damage resistant finish, which will effectively convey a 
              brand's marketing message, there will be a role for in-mould labelling.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
        <Footer/>
    </div>
  )
}

export default Blog