import React from 'react'
import banimg from '../assets/banner.webp'
import mimg from '../assets/mobile.jpg'

function Banner() {
  return (
    <div className='relative'>
      <div className='absolute top-1/2 left-1/2 md:top-1/3 -translate-x-1/2 -translate-y-1/2 '>
       <p className='text-3xl font-extrabold sm:text-5xl text-white md:mb-10 mb-4 '>Premier In-Mould Labeling Solutions</p>
       <p className='text-white '>Plazteck is an experienced precision manufacturer of In-mould labeled packaging in Pakistan.</p>
      </div>
      <div className=''>
      <img className='md:block hidden' src={banimg}/>
      <img className='md:hidden block w-screen h-screen' src={mimg}/>
      </div>
    </div>
  )
}

export default Banner