import React from 'react'
import ContactC from '../components/Contact'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Contact() {
  return (
    <div>
        <Header/>
        <ContactC/>
        <Footer/>
    </div>
  )
}

export default Contact