import React from 'react'
import robot from '../assets/robot.webp'
import iml from '../assets/iml.webp'

function Blog() {
  return (
    <div>
        <section>
  <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
    

    <ul className="grid grid-cols-1 gap-4 mt-8 lg:grid-cols-2">
      

    

      <li className="">
        <a  className="relative block group bg-red-700">
          <img
            src={robot}
            alt=""
            className="object-cover  transition duration-500  opacity-0 "
          />

          <div
            className="absolute md:top-1/3 top-1/3 px-10 "
          >
            <h3 className="text-xl mx-auto font-medium text-white">Get to know why companies consider IML as a crucial part of their branding strategy.</h3>

            
          </div>
        </a>
      </li>
      <li>
        <a href="/blog" className="relative block group">
        <h3 className="text-xl font-medium text-red-700 absolute top-4 left-2">Better Branding With IML</h3>
          <img
            src={iml}
            alt=""
            className="object-cover w-full transition duration-500   hover:opacity-90"
          />

          <div
            className="absolute inset-0 flex flex-col items-start justify-end p-6"
          >
            

            <span
              className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
            >
              Explore
            </span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</section>
    </div>
  )
}

export default Blog